import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { format } from 'date-fns';

import { BlogQuery } from '../../graphql-types';
import { Layout } from '../components/Layout';
import { Container } from '../components/Container';
import { Section } from '../components/Section';
import { GoToLink } from '../components/GoToLink';
import SEO from '../components/seo';
import { getPathFromContentfulType } from '../helpers/getPathFromContentfulType';
import styles from './blog.module.css';

interface BlogProps {
  data: BlogQuery;
}

const BlogPage = ({ data }: BlogProps) => {
  const pageInfo = data.allContentfulPost.pageInfo;
  const title =
    pageInfo.currentPage === 1
      ? 'Blog'
      : `Blog - Page ${pageInfo.currentPage} of ${pageInfo.pageCount}`;

  return (
    <Layout>
      <SEO title={title} />

      <Section>
        <Container narrow>
          {data.allContentfulPost &&
            data.allContentfulPost.edges
              .map(p => p.node)
              .map(p => (
                <article key={p.id} className={styles.post}>
                  <header className={styles.header}>
                    <h2 className={styles.heading}>
                      <Link
                        to={getPathFromContentfulType.post(p?.slug as string)}
                      >
                        {p.title}
                      </Link>
                    </h2>
                    <time
                      className={styles.published}
                      dateTime={p?.date as string}
                    >
                      Posted on{' '}
                      {format(new Date(p?.date as string), 'MMM d, yyyy')}
                    </time>
                  </header>
                  <div className={styles.intro}>
                    <p>{p.intro?.intro}</p>
                    <GoToLink
                      to={getPathFromContentfulType.post(p?.slug as string)}
                    >
                      Read more
                    </GoToLink>
                  </div>
                </article>
              ))}
          {pageInfo.hasNextPage || pageInfo.hasPreviousPage ? (
            <div className={styles.pagination}>
              {pageInfo.hasNextPage && (
                <Link
                  to={`/blog/page/${Math.min(
                    pageInfo.currentPage + 1,
                    pageInfo.pageCount
                  )}`}
                >
                  « Older Entries
                </Link>
              )}
              {pageInfo.hasPreviousPage && (
                <Link
                  to={
                    pageInfo.currentPage === 2
                      ? '/blog'
                      : `/blog/page/${Math.max(pageInfo.currentPage - 1, 1)}`
                  }
                >
                  Next Entries »
                </Link>
              )}
            </div>
          ) : null}
        </Container>
      </Section>
    </Layout>
  );
};

// TODO: Move to template. Replace limit with limit from config.
export const query = graphql`
  query Blog($pageSize: Int!, $skip: Int!) {
    allContentfulPost(
      sort: { fields: date, order: DESC }
      limit: $pageSize
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          title
          date
          intro {
            intro
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;

export default BlogPage;
